import { DATETIME_DISPLAY_FORMATS, zonedTimeFormat } from "@inferno/renderer-shared-core";
import "./ShowTime.style.scss";

interface ShowTimeProps {
  zone_id: string;
  start: number;
  end: number;
}

export const ShowTime = (props: ShowTimeProps) => {
  const formattedStart = zonedTimeFormat({
    date: props.start,
    timezone: props.zone_id,
    outputFormat: DATETIME_DISPLAY_FORMATS.TWELVE_HOUR_UNPADDED,
  });

  const formattedEnd = zonedTimeFormat({
    date: props.end,
    timezone: props.zone_id,
    outputFormat: DATETIME_DISPLAY_FORMATS.TWELVE_HOUR_UNPADDED,
  });

  return (
    <div className="show-time">
      <time data-output="start-time">{formattedStart}</time>
      &nbsp;-&nbsp;
      <time data-output="end-time">{formattedEnd}</time>
    </div>
  );
};

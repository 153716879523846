import { Fragment } from "react";
import type { AppEnvironment } from "@inferno/renderer-shared-core";
import type { OnAirShowFragment } from "@ihr-radioedit/inferno-webapi";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { ImageSource, LiveCard, LiveCardImage, LiveCardProps } from "../livecard/LiveCard.component";
import { LiveCardType } from "../livecard/LiveCardType";
import { ShowTime } from "./ShowTime.component";

interface OnAirScheduleCurrentProps {
  currentShow: OnAirShowFragment;
  timezone: string;
  liveUrl: string;
  stationLogo: string | null | undefined;
  env: AppEnvironment;
}

export const OnAirScheduleCurrent = (props: OnAirScheduleCurrentProps) => {
  const { currentShow, timezone, stationLogo, env } = props;
  let image = formatImage(stationLogo || "", env.IMAGE_HOST) || placeholderImage;
  let source: ImageSource = "station";
  let name = currentShow.name;
  let href: string | undefined;
  if (currentShow.showSite?.configByLookup) {
    name = currentShow.showSite?.configByLookup?.sections?.general?.name || "";
    const imageUrl = currentShow.showSite?.configByLookup?.sections?.general?.thumbnail_image?.asset?.href;
    if (currentShow.showSite?.slug) {
      href = `/featured/${currentShow.showSite.slug}/`;
    }
    if (imageUrl) {
      image = formatImage(imageUrl, env.IMAGE_HOST);
      source = "show";
    }
  }

  const cardImage: LiveCardImage = {
    url: image,
    source,
  };

  const cardDetails: LiveCardProps = {
    image: cardImage,
    title: name,
    type: LiveCardType.ONAIR,
    url: href,
    equalizer: true,
    target: "_self",
    content: <ShowTime zone_id={timezone} start={currentShow.startMs} end={currentShow.stopMs} />,
  };
  return (
    <Fragment>
      <LiveCard {...cardDetails} />
      <hr className="divider" />
    </Fragment>
  );
};

export default OnAirScheduleCurrent;
